import React from 'react';
import {
    Box,
    Heading,
    Flex,
    GridItem,
    Grid,
    Text,
    HStack,
    Icon,
    Spacer,
    IconButton,
    useBreakpointValue,
    VStack,
    UnorderedList,
    ListItem,
    Container,
    Center,

} from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import OleniumLogo from './OleniumLogo';
import { BsGlobe, BsPersonCircle, BsInstagram, BsArrowRight } from 'react-icons/bs';
import { motion } from 'framer-motion';


const WhatIsOlenium = () => {

    const buttons = [
        {
            colour: "#0085FF",
            prompts: "Explore Olenium",
            icon: BsGlobe,
            link: "https://olenium.co.uk"
        },
        {
            colour: "#0085FF",
            prompts: "Follow our Instagram",
            icon: BsInstagram,
            link: "https://www.instagram.com/olenium/"
        },
    ];


    return (
        <Flex justifyContent="center" alignItems="center" p={5}>
            <Box
                overflow="hidden"
                background="#121212"
                color="white"
                borderRadius="2xl"
                p={{ base: 5, md: 10 }}
                my={5}
                textAlign="center"
                w="full"
                maxW="1200px"
            >
                <Flex justifyContent="center" mb={5}>
                    <OleniumLogo />
                </Flex>
                <Box borderRadius="10px" overflow="hidden">
                    <ReactPlayer
                        url="https://olenium-public-resources.s3.eu-west-1.amazonaws.com/Olenium+Pitch+Deck.mp4"
                        controls
                        playing={true}
                        width="100%"
                        height="auto"
                    />
                </Box>

                <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={6} mt={6}>
                    <GridItem colSpan={{ base: 3, md: 2 }} textAlign="left">
                        <Heading as="h2" size="sm" mb={3}>
                            Olenium Master Plan
                        </Heading>
                        <Text fontSize="md" mb={3} color="#999999">
                            Join us on a journey through Olenium's master plan to revolutionise the design industry. This explainer video unveils how Olenium's innovative platform uses cutting-edge AI technology to effortlessly connect clients with top-tier design providers. Discover the transformative potential of our SideKick Recommendation system, which seamlessly matches project briefs with the ideal creative talent. We'll explore the bespoke features like Brand Assets storage and Team Chat that streamline collaboration and enhance efficiency.
                        </Text>
                        <Text fontSize="md" mb={3} color="#999999">
                            Learn about the platform's core pillars: Team Collaboration, Global Network, and SideKick AI, and how they come together to form a robust system for dynamic business scaling and improved business intelligence.
                        </Text>
                        <Text fontSize="md" mb={3} color="#999999">
                            This video also sheds light on our competitive landscape, pinpointing Olenium's advantages over established players like Fiverr and UpWork. Lastly, we share our strategic plans for the grant's utilisation to further our mission and elevate the platform to new heights.
                        </Text>
                        <Text fontSize="md" mb={3} color="#999999">
                            Olenium is not just changing the game; it's creating a whole new playing field for creative project management. Watch now and see the future of design collaborations unfold.
                        </Text>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, md: 1 }} justifyContent={"center"} alignItems={"center"} textAlign="left">
                        {buttons.map((prompt, index) => (
                            <Center> {/* This should center the buttons */}
                                <ButtonContainer
                                    colour={prompt.colour}
                                    prompts={prompt.prompts}
                                    icon={prompt.icon}
                                    link={prompt.link}
                                />
                            </Center>
                        ))}
                    </GridItem>
                </Grid>
            </Box>
        </Flex >
    );
};


const ButtonContainer = ({
    colour,
    prompts,
    icon,
    link,
    isLoading,
}) => {
    const [r, g, b] = [
        parseInt(colour.slice(1, 3), 16),
        parseInt(colour.slice(3, 5), 16),
        parseInt(colour.slice(5, 7), 16),
    ];

    // Set the alpha value for transparency (0.0 to 1.0)
    const alpha = 0.2;

    // Create the rgba color string
    const rgbaColour = `rgba(${r}, ${g}, ${b}, ${alpha})`;

    return (
        <Box
            w="350px"
            p="4"
            borderWidth={"1px"}
            borderColor={"oleniumBlack.300"}
            borderRadius="20px"
            boxShadow={"base"}
            isDisabled={isLoading}
            my={2}

        >
            <HStack spacing={4} align="center" cursor="pointer">
                <Box
                    w={{ base: "40px", md: "50px" }}
                    h={{ base: "40px", md: "50px" }}
                    borderRadius="10px"
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                    bg={rgbaColour}
                    aspectRatio={1}
                >
                    <Icon as={icon} color={colour} w={{ base: "20px", md: "30px" }} h={{ base: "20px", md: "30px" }} />
                </Box>
                <Text
                    fontSize={useBreakpointValue({ base: "xs", md: "md" })}
                    fontWeight="bold"
                >
                    {prompts}
                </Text>
                <Spacer />
                <IconButton
                    icon={<BsArrowRight />}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    onClick={() => {
                        window.open(link);
                    }
                    }
                />
            </HStack>
        </Box>
    );
};


export default WhatIsOlenium;
