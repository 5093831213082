import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import { ChakraProvider, extendTheme, CSSReset, Box, Button } from '@chakra-ui/react';

// Define your custom theme
const theme = extendTheme({
  config: {
    initialColorMode: 'dark',  // Add this line to set the initial color mode to dark
    useSystemColorMode: false, // Optional, set to true if you want to use the system setting
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: 'black',
        color: 'white',
      },
    },
  },
  components: {
    Button: {
      variants: {
        oleniumBlue: {
          bg: '#0085FF',
          color: 'white',
          _hover: {
            bg: '#0069C9',
          },
          _active: {
            bg: '#0085FF',
          },
        },
      },
    },
  },
  fonts: {
    // Use Helvetica as the font
    heading: "Helvetica, sans-serif",
    body: "Helvetica, sans-serif",
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
