import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Dashboard from "./Dashboard";
import WhatIsOlenium from "./WhatIsOlenium";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/what-is-olenium" element={<WhatIsOlenium />} />
        <Route path="/introduction-video" element={<WhatIsOlenium />} />
      </Routes>
    </Router>
  );
}

export default App;
